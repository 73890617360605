<template>
    <hb-modal v-model="dialog" size="large" title="Update Task" show-help-link>
        <template v-slot:content>
            <hb-form label="Assign to"
                description="Select one or multiple role(s) AND/OR user(s) to filter from.">
                <hb-combobox class="mh-550px" :items="assignToList" v-model="assignes" item-text="name"
                    item-value="id" prepend-inner-icon="mdi-magnify" label="Search roles or users" />
            </hb-form>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)"
                @click="applyFilters">Update Task
            </hb-btn>
        </template>
    </hb-modal>
</template>

<script>
export default {
    name: 'UpdateSystemTask',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            assignes: [],
            assignToList: [
                { id: 1, name: 'Lead Manager' },
                { id: 2, name: 'Property Manager' },
                { id: 3, name: 'Administrator' }
            ]
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        applyFilters() {
            console.log('this.filterData :>> ', this.assignes);
            this.dialog = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.mh-550px {
    min-height: 550px;
}
</style>